import * as actions from "./actions";
import * as moment from "moment";

import { AuthState, User } from "./reducers/auth";
import { BackTop, ConfigProvider, Layout, Row, message } from "antd";
import Joyride, { CallBackProps } from "react-joyride";
import { MaxWidth, State } from "./interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ConnectedRouter } from "connected-react-router";
import DealerPortalAccess from "./pages/dealerportalaccess";
import DealerportalLoginPage from "./pages/dealerportalLogin";
import Details from "./pages/details";
import { FiltersState } from "./reducers/filters";
import ForgotPasswordPage from "./pages/forgotpassword";
import { ForumsState } from "./reducers/forums";
import Home from "./pages/home";
import { ItemsState } from "./reducers/items";
import { LanguageState } from "./reducers/language";
import Library from "./pages/library";
import LoginPage from "./pages/login";
import LogoutPage from "./pages/logout";
import Nav from "./components/nav";
//import NewsPage from "./pages/news";
//import NewsDetails from "./pages/news/details";
import { NewsState } from "./reducers/news";
import PrivateRoute from "./components/privateroute";
import ReactGA from "react-ga";
import RedirectSharedToken from "./pages/redirectSharedToken";
import RedirectToken from "./pages/redirectToken";
import SelectBrand from "./pages/select-brand";
import { ShareState } from "./reducers/share";
import { detectIE11 } from "./utils";
import { history } from "./stores/configureStore";
import queryString from "query-string";
import { useMedia } from "react-use";

// import { useWhyDidYouUpdate } from "./hooks/useWhyDidYouUpdate";


const { Content } = Layout;

const parsed = queryString.parse(history.location.search);
// console.log("parsed", parsed);
if (parsed.brand) {
  localStorage.setItem("brandq", parsed.brand as string);
}
const from = history.location.pathname;
if (from && !from.includes('/dealer/loginWithToken/')){ 
  localStorage.setItem("from", from);
};

const App = () => {
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const {
    user,
    hasError: loginHasError,
    errorMessage: loginError,
    forgotPasswordMessage
  } = auth;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const {
    hasError: filtersHasError,
    errorMessage: filtersError,
    selectedTheme
  } = filters;

  const _language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const {
    labels,
    selectedLanguage,
    hasError: languageHasError,
    errorMessage: languageError
  } = _language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const { hasError: itemsHasError, errorMessage: itemsError } = items;

  const share: ShareState = useSelector(
    (state: State) => state.share,
    shallowEqual
  );

  const { hasError: shareHasError, errorMessage: shareError } = share;

  const news: NewsState = useSelector(
    (state: State) => state.news,
    shallowEqual
  );

  const { hasError: newsHasError, errorMessage: newsError } = news;

  const forums: ForumsState = useSelector(
    (state: State) => state.forums,
    shallowEqual
  );

  const { hasError: forumsHasError, errorMessage: forumsError } = forums;

  const dispatch = useDispatch();

  const onForgotPasswordSuccess = useCallback(
    (path) => dispatch(actions.redirectToPage(path)),
    [dispatch]
  );

  const [locale, setLocale] = useState(undefined);
  const [run, setRun] = useState(false);
  // const [steps, setSteps] = useState<{
  //     target: string;
  //     content: string;
  //     placement: string;
  //   }[]>([]);
  const [steps, setSteps] = useState<any[]>([]);
  const [localeChanged, setLocaleChanged] = useState(false);
  const [language, setLanguage] = useState("");

  const isSmall = useMedia(MaxWidth.MD);

  const isIE11 = detectIE11();

  // useWhyDidYouUpdate("App", props);

  useEffect(() => {
    // ReactGA
    // avvio analytics per questa pagina
    ReactGA.pageview(history.location.pathname);
    // e tutte le pagine successive
    history.listen((location: any, action: any) => {
      // ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      // resetto la classe modal se non sono sul dettaglio repo
      if (!location.pathname.includes("details")) {
        document.body.classList.remove("modal-open");
      } else {
        document.body.classList.add("modal-open");
      }
      // salvo la location su localStorage
      // localStorage.setItem("from", location.pathname);
    });
  }, [dispatch]);

  useEffect(() => {
    // componentDidUpdate
    // console.log("componentDidUpdate");

    if (loginHasError) {
      message.error(loginError);
      refreshXCsrfToken(loginError);
    }
    if (filtersHasError) {
      message.error(filtersError);
      refreshXCsrfToken(filtersError);
    }
    if (itemsHasError) {
      message.error(itemsError);
      refreshXCsrfToken(itemsError);
    }
    if (languageHasError) {
      message.error(languageError);
      refreshXCsrfToken(languageError);
    }
    if (shareHasError) {
      message.error(shareError);
      refreshXCsrfToken(shareError);
    }
    if (newsHasError) {
      message.error(newsError);
      refreshXCsrfToken(newsError);
    }
    if (forumsHasError) {
      message.error(forumsError);
      refreshXCsrfToken(forumsError);
    }
    if (forgotPasswordMessage && labels) {
      message.success(labels.recoverPasswordSuccessMessage, 2, () =>
        onForgotPasswordSuccess("/")
      );
    }
  }, [
    filtersError,
    filtersHasError,
    forgotPasswordMessage,
    forumsError,
    forumsHasError,
    itemsError,
    itemsHasError,
    labels,
    languageError,
    languageHasError,
    loginError,
    loginHasError,
    newsError,
    newsHasError,
    onForgotPasswordSuccess,
    shareError,
    shareHasError
  ]);

  useEffect(() => {
    // se cambia la lingua importo il nuovo locale
    // console.log("selectedLanguage ", selectedLanguage);
    if (selectedLanguage && selectedLanguage.id !== language) {
      _loadAsyncData(selectedLanguage!.id);
    }
  }, [language, selectedLanguage]); // getDerivedStateFromProps

  // se il locale è cambiato devo settare joyride con i testi in lingua
  if (labels && localeChanged) {
    // console.log("joyride con i testi in lingua");
    // Retrieve
    const hideHelp = localStorage.getItem("hideHelp");
    // console.log("hideHelp ", hideHelp);
    if (hideHelp !== "true") {
      const content = labels.helpText;
      setRun(true);
      setLocaleChanged(false);
      setSteps([
        {
          target: ".help-btn",
          content,
          placement: "left"
        }
      ]);
    }
  }

  const refreshXCsrfToken = (_message: string | null) => {
    if (_message?.indexOf("X-CSRF-Token") !== -1) {
      console.log(_message);
    }
  };

  const _loadAsyncData = async (id: string) => {
    // console.log("_loadAsyncData id ", id);
    let results = [undefined, undefined];
    switch (id) {
      case "it":
        results = await Promise.all([
          import("antd/lib/locale-provider/it_IT"),
          import("moment/locale/it")
        ]);
        break;

      case "es":
        results = await Promise.all([
          import("antd/lib/locale-provider/es_ES"),
          import("moment/locale/es")
        ]);
        break;

      case "fr":
        results = await Promise.all([
          import("antd/lib/locale-provider/fr_FR"),
          import("moment/locale/fr")
        ]);
        break;

      case "de":
        results = await Promise.all([
          import("antd/lib/locale-provider/de_DE"),
          import("moment/locale/de")
        ]);
        break;

      default:
        results = [undefined, undefined];
    }
    // destrutturo i risultati
    const [antdLocale] = results;

    moment.locale(id);
    setLocale(
      antdLocale !== undefined ? (antdLocale as any)!.default : undefined
    );
    setLocaleChanged(true);
    setLanguage(id);
  };

  const helpCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === "finished") {
      // console.log("data ", data);
      localStorage.setItem("hideHelp", "true");
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <ConnectedRouter history={history}>
        <Layout
          className={user ? "" : "not-logged-in"}
          style={{ minHeight: "100vh" }}
        >
          {!isSmall && !isIE11 && (
            <Joyride
              steps={steps}
              run={run}
              styles={{
                options: {
                  primaryColor: selectedTheme.primary
                }
              }}
              callback={helpCallback}
            />
          )}
          <Nav />
          <BackTop />
          <Content className="main-content">
            <Switch>
              <Route exact path="/old" component={LoginPage} />
              <Route exact path="/" component={DealerportalLoginPage} />
              <Route
                exact
                path="/dealer/loginWithToken/:access_token/:refresh_token"
                component={DealerPortalAccess}
              />              
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <PrivateRoute
                exact
                path="/s/:token"
                component={RedirectToken}
                user={user as User}
              />
              <PrivateRoute
                exact
                path="/item/shared/:token"
                component={RedirectSharedToken}
                user={user as User}
              />
              <PrivateRoute
                exact
                path="/select-brand"
                component={SelectBrand}
                user={user as User}
              />
              <PrivateRoute
                exact
                path="/:brand/home"
                component={Home}
                user={user as User}
              />
              <PrivateRoute
                path="/:brand/library"
                component={Library}
                user={user as User}
              />
              <PrivateRoute
                path="/:brand/details/:itemId"
                component={Details}
                user={user as User}
              />
              {/*<Route
                exact
                path="/dealer/:access_token/:refresh_token"
                component={DealerPortalAccess}
              />*/}
              {/*
              <PrivateRoute
                exact
                path="/(news|news-details)"
                component={NewsPage}
                user={user as User}
              />
              */}
              <Route component={NoMatch} />
            </Switch>
            {/*
            <PrivateRoute
              path="/news-details/:itemId"
              component={NewsDetails}
              user={user as User}
            />
            */}
            <Route path="/logout" component={LogoutPage} />
          </Content>
          {/* <Footer style={{ textAlign: "center" }}>
            © 2017 CNH Industrial America LLC. Tutti i diritti riservati CASE e
            CNH Capital sono marchi registrati di CNH Industrial America LLC.
          </Footer> */}
        </Layout>
      </ConnectedRouter>
    </ConfigProvider>
  );
};

const NoMatch = () => {
  localStorage.removeItem("from");
  return <Redirect to="/" />;
};

export default App;
