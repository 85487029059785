import "video-react/dist/video-react.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as actions from "../../actions";

import { Button, Layout, Popover, Row, Tag } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { DetailsParamTypes, MaxWidth, State } from "../../interfaces";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import DownloadBtn from "../../components/repo/downloadbtn";
import DynamicIcon from "../../components/DynamicIcon";
import FileLoader from "../../components/FileLoader";
import { FiltersState } from "../../reducers/filters";
import { ItemsState } from "../../reducers/items";
import { LanguageState } from "../../reducers/language";
import ShareBtn from "../../components/repo/share/shareBtn";
import { formatDate } from "../../utils";
import { useMedia } from "react-use";

const { Content, Header } = Layout;

interface Props {}

const Details: React.FC<Props> = (props) => {
  let history = useHistory();
  let { itemId } = useParams<DetailsParamTypes>();

  const [type, setType] = useState("image");
  const isSmall = useMedia(MaxWidth.MD);

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { selectedTheme, selectedBrand } = filters;
  const { byId: typeById, all: allTypes } = filters.typologies.entities;
  const { byId: tagById } = filters.tags.entities;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const { entities, selectedItem, userCanShare } = items;

  const [isAutoLogin] = useState(selectedItem === null);

  const dispatch = useDispatch();

  useEffect(() => {
    const getItemFromID = (id: string) => {
      if (selectedItem !== null && selectedItem !== undefined) {
      } else {
        dispatch(actions.getItemById(id));
      }
    };
    getItemFromID(itemId as string);
  }, [entities.all, itemId, dispatch, selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      const index = selectedItem.previewType.indexOf("/");
      setType(selectedItem.previewType.substring(0, index));
      // console.log("selectedItem ", type);
    }
  }, [selectedItem]);

  const onBack = () => {
    // dispatch(actions.selectItem(null));
    if (isAutoLogin) {
      history.push(`/${selectedBrand?.code}/home`);
    } else {
      // history.push("/library");
      history.goBack();
    }
  };

  const handleOnShare = () => {
    dispatch(actions.showShareModal(true, selectedItem));
    onBack();
  };

  const getTypeIcon = (): string | null => {
    if (selectedItem && allTypes.length > 0) {
      const baseTypeId = filters.typologies.selectedTypologies?.[0]
      const icon = typeById[selectedItem.typeID[0]]?.icon || typeById[baseTypeId]?.icon;
      return icon;
    }
    return null;
  };

  return (
    <Layout className="details">
      <Header style={{ position: "fixed", width: "100%" }}>
        {selectedItem && (
          <Row justify="space-between" align="middle">
            <h2 className="details-title">
              <DynamicIcon type={getTypeIcon()} />
              {selectedItem.name}
            </h2>

            {!isSmall ? (
              <Popover
                overlayClassName="popover-item-info"
                placement="bottomRight"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                content={
                  <>
                    <h4>{formatDate(selectedItem.publishDate)}</h4>
                    {selectedItem.tags &&
                      selectedItem.tags.map((tag) => {
                        return (
                          <Tag key={tag} className="item-tag" color="#999">
                            {tagById && tagById[tag]?.name}
                          </Tag>
                        );
                      })}
                  </>
                }
              >
                <Button className="info-btn">
                  <InfoCircleOutlined />
                </Button>
              </Popover>
            ) : null}
            {labels && (
              <DownloadBtn
                direction="bottomRight"
                files={selectedItem.files}
                label={isSmall ? "" : `${labels.download}`}
                itemID={selectedItem.id}
                theme={selectedTheme}
              />
            )}
            {labels && userCanShare && (
              <ShareBtn
                direction="bottomRight"
                files={selectedItem.files}
                itemID={selectedItem.id}
                onShare={handleOnShare}
              />
            )}
            <Button
              className="nav-bnt back-btn"
              type="default"
              onClick={onBack}
            >
              <CloseOutlined />
            </Button>
          </Row>
        )}
      </Header>
      <Content className="details-content">
        {selectedItem && (
          <div className="details-wrapper">
            {type === "image" ? (
              <FileLoader
                clssName="detail-image"
                fileUrl={selectedItem.previewUrl}
                type="css"
              />
            ) : (
              <FileLoader
                clssName="detail-video vjs-big-play-centered"
                fileUrl={selectedItem.previewUrl}
                type="video"
                poster={selectedItem.previewPosterUrl}
              />
            )}
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Details;
